<template>
  <div class="account">
    <div class="row animated fadeIn">
      <div class="col-lg-7 col-md-7">
        <div class="card">
          <div class="loader loader-backdrop" v-if="isAccountLoading"></div>
          <div class="card-block">
            <div class="row">
              <div class="col-lg-5">
                <div class="userphoto">
                  <label for="userphoto">
                    <img alt="" class="userphoto-img" :src="currentUserData.photo" />
                    <div class="userphoto-edit">
                      <i class="icon-camera userphoto-icon big"></i>
                      <i
                        class="fa fa-remove text-danger userphoto-delete small"
                        @click.stop.prevent="deletePhoto()"
                        data-action="delete"
                        v-if="currentUserData.photo !== '/static/img/avatars/user.svg'"
                      ></i>
                    </div>
                    <input
                      type="file"
                      id="userphoto"
                      if="userphoto"
                      @change="previewImage"
                      name="userphoto"
                      class="form-control userphoto-field"
                      v-validate="'image|size:100'"
                    />
                  </label>
                </div>
                <span v-show="errors.has('userphoto')" class="help is-danger">{{ errors.first('userphoto') }}</span>
              </div>
              <div class="col-lg-6">
                <div class="userprofile">
                  <div class="userprofile-name">
                    <p class="username">{{ currentUserData.username }}</p>
                    <p class="role">
                      <i class="fa fa-user"></i>
                      {{ $t(`general.role.${currentUserData.role}`) }}
                    </p>
                    <p class="location">
                      <i class="fa fa-map-o"></i>
                      {{ locationsItemsFromStoe[currentUserData.base_location].name }}
                    </p>
                    <p class="email" v-if="emailField">
                      <i class="fa fa-envelope-o"></i>
                      <a
                        href="#"
                        @click.prevent="(editEmail = false), (editEmailField = true), (emailField = false)"
                        class="useremail-edit"
                        v-if="editEmail"
                      >
                        <span v-if="currentUserData.email === ''">{{ $t(`account.addEmail`) }}</span>
                        <span v-else>{{ currentUserData.email }}</span>
                      </a>
                    </p>
                    <div class="input-group" v-if="editEmailField">
                      <input
                        type="text"
                        class="form-control input-sm"
                        name="email"
                        :placeholder="$t('account.enterEmailPlaceholder')"
                        v-model="currentUserData.email"
                        v-validate="'required|email'"
                        @keyup.enter="updateUserEmail"
                        :class="{ input: true, 'is-danger': errors.has('email') }"
                      />
                      <span class="input-group-btn">
                        <button class="btn btn-sm btn-outline-success" @click="updateUserEmail">
                          {{ $t(`account.save`) }}
                        </button>
                      </span>
                    </div>

                    <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>
                  </div>
                  <div class="userprofile-password" v-if="!isDisable">
                    <button type="button" class="btn btn-sm btn-outline-info" @click="changePassword = true">
                      {{ $t(`account.changePasswordButton`) }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
      title="Change password"
      class="change-password"
      v-model="changePassword"
      @ok="changePassword = false"
      @cancel="cancelAndCloseChangePasswordModal"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t(`account.changePassword`) }}</h4>
      <div class="modal-body">
        <div class="form-group">
          <label for="oldpass">{{ $t(`account.oldPassword`) }}</label>
          <div class="input-group">
            <input
              :data-vv-as="$t('account.oldPassword')"
              ref="oldPassword"
              type="password"
              id="oldpass"
              v-model="oldPassword"
              :placeholder="$t('account.enterOldPasswordPlaceholder')"
              name="oldpass"
              class="form-control oldpassword"
              v-validate="'required'"
              data-vv-validate-on="input"
              :class="{ input: true, 'is-danger': errors.has('oldpass') }"
            />
            <span class="input-group-addon" @click="showPassword"><i ref="oldPasswordIcon" class="fa fa-eye"></i></span>
          </div>
          <span v-show="errors.has('oldpass')" class="help is-danger">
            {{ errors.first('oldpass') }}
          </span>
        </div>
        <div class="form-group">
          <label for="newpass">{{ $t(`account.newPassword`) }}</label>
          <div class="input-group">
            <input
              :data-vv-as="$t('account.newPassword')"
              type="password"
              id="newpass"
              v-model="newPassword"
              :placeholder="$t('account.enterNewPasswordPlaceholder')"
              ref="newpass"
              name="newpass"
              class="form-control"
              v-validate="mixRulesForUserPasswordCheck('required')"
              data-vv-validate-on="input"
              :class="{ input: true, 'is-danger': errors.has('newpass') }"
            />
            <span class="input-group-addon" @click="showPassword"><i ref="newPasswordIcon" class="fa fa-eye"></i></span>
          </div>
          <span v-show="errors.has('newpass')" class="help is-danger">
            {{ errors.first('newpass') }}
          </span>
        </div>
        <div class="form-group">
          <label for="confirmpass">{{ $t(`account.confirmNewPassword`) }}</label>
          <div class="input-group">
            <input
               :data-vv-as="$t('account.confirmNewPassword')"
              ref="confirmPassword"
              type="password"
              id="confirmpass"
              v-model="confirmNewPassword"
              :placeholder="$t('account.confirmNewPasswordPlaceholder')"
              name="confirmnewpass"
              class="form-control"
              v-validate="'required|confirmed:newpass'"
              data-vv-validate-on="input"
              :class="{ input: true, 'is-danger': errors.has('confirmnewpass') }"
            />
            <span class="input-group-addon" @click="showPassword">
              <i ref="confirmPasswordIcon" class="fa fa-eye"></i>
            </span>
          </div>
          <span v-show="errors.has('confirmnewpass')" class="help is-danger">
            {{ errors.first('confirmnewpass') }}
          </span>
        </div>
        <div class="form-group">
          <Password-generator @passwordGenerated="passwordGenerated"></Password-generator>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="cancelAndCloseChangePasswordModal">
          {{ $t('general.cancel') }}
        </button>
        <button type="button" class="btn btn-success" @click="warningPassword">{{ $t(`account.change`) }}</button>
      </div>
    </Modal>

    <Modal
      title="Attention"
      class="modal-danger"
      small
      v-model="confirmChange"
      @ok="confirmChange = false"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t(`account.attention`) }}</h4>
      <div class="modal-body">{{ $t(`account.attentionMessage`) }}</div>
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="confirmChange = false">
          {{ $t('general.cancel') }}
        </button>
        <button type="button" class="btn btn-outline-success" @click="updateUserPassword">
          {{ $t(`account.confirm`) }}
        </button>
      </div>
    </Modal>

    <Modal title="New photo" class="modal" small v-model="changePhoto" @ok="changePhoto = false" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t(`account.newPhoto`) }}</h4>
      <div class="modal-body modal-photo"><img class="new-photo" :src="newPhoto" /></div>
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="changePhoto = false">{{ $t('general.cancel') }}</button>
        <button type="button" class="btn btn-outline-success" @click="updateUserData">
          {{ $t(`account.confirm`) }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import auth from '../auth/auth';
import Modal from '../components/Modal.vue';
import PasswordGenerator from '../components/PasswordGenerator.vue';
import helpers from '../helpers';

export default {
  name: 'Account',
  components: {
    Modal,
    PasswordGenerator
  },
  data() {
    return {
      changePassword: false,
      confirmChange: false,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      editEmail: true,
      emailField: true,
      editEmailField: false,
      changePhoto: false,
      userPhoto: '',
      updatedUserData: {
        photo: '',
        email: ''
      },
      locationsItems: {
        name: {}
      }
    };
  },
  computed: {
    currentUserData() {
      this.updatedUserData = JSON.parse(JSON.stringify(this.$store.state.userData));
      return this.$store.state.userData;
    },
    newPhoto() {
      return this.updatedUserData.photo;
    },
    locationsItemsFromStoe() {
      this.locationsItems = JSON.parse(JSON.stringify(this.$store.state.locationsItems));
      return this.$store.state.locationsItems;
    },
    isAccountLoading() {
      return this.$store.state.loadingAccount;
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    errorsList() {
      return this.errors.errors;
    }
  },
  methods: {
    cancelAndCloseChangePasswordModal() {
      this.confirmNewPassword = '';
      this.newPassword = '';
      this.oldPassword = '';
      this.changePassword = false;
      this.errors.remove('confirmnewpass');
      this.errors.remove('newpass');
      this.errors.remove('oldpass');
    },
    mixRulesForUserPasswordCheck(rules) {
      return helpers.mixRulesForUserPasswordCheck(rules);
    },
    passwordGenerated(generatedPassword) {
      const { oldPassword } = this.$refs;
      const newPassword = this.$refs.newpass;
      const { confirmPassword } = this.$refs;

      const { oldPasswordIcon } = this.$refs;
      const { newPasswordIcon } = this.$refs;
      const { confirmPasswordIcon } = this.$refs;

      if (oldPassword.type === 'password') {
        oldPassword.type = 'text';
        oldPasswordIcon.className = 'fa fa-eye-slash';
      }
      if (newPassword.type === 'password') {
        newPassword.type = 'text';
        newPasswordIcon.className = 'fa fa-eye-slash';
      }
      if (confirmPassword.type === 'password') {
        confirmPassword.type = 'text';
        confirmPasswordIcon.className = 'fa fa-eye-slash';
      }
      this.newPassword = generatedPassword;
      this.confirmNewPassword = generatedPassword;
    },
    updateUserData() {
      this.$store.commit('toggleLoadingAccount');
      const updateUserDataQuery = {
        action: 'U',
        items: { [this.updatedUserData.id]: this.updatedUserData }
      };
      this.axios.post(`${window.RESTAPI || ''}/api/users`, updateUserDataQuery).then(
        (response) => {
          if (response.data.status === 'success') {
            const id = Object.keys(response.data.data.items)[0];
            this.$store.commit('setUserData', response.data.data.items[id]);
            this.$store.commit('toggleLoadingAccount');
          } else {
            VueNotifications.error({ message: response.data.description });
            this.$store.commit('toggleLoadingAccount');
          }
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.$store.commit('toggleLoadingAccount');
          this.error = err;
        }
      );
      this.changePhoto = false;
    },
    updateUserEmail() {
      this.$validator.validateAll({ email: this.updatedUserData.email }).then((result) => {
        if (result) {
          this.editEmail = true;
          this.editEmailField = false;
          this.emailField = true;
          this.updateUserData();
        }
      });
    },
    updateUserPassword() {
      this.$store.commit('toggleLoadingAccount');
      const updateUserPasswordQuery = {
        username: this.$store.state.userData.username,
        oldpassword: this.oldPassword,
        password: this.newPassword
      };
      this.axios.post(`${window.RESTAPI || ''}/auth/changePassword`, updateUserPasswordQuery).then(
        (response) => {
          this.$store.commit('toggleLoadingAccount');
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            this.confirmChange = false;
            this.cancelAndCloseChangePasswordModal();
            return;
          }
          auth.logout(this);
        },
        (err) => {
          this.error = err;
          VueNotifications.error({ message: err });
          this.$store.commit('toggleLoadingAccount');
        }
      );
    },
    warningPassword() {
      this.$validator
        .validateAll({ oldpass: this.oldPassword, newpass: this.newPassword, confirmnewpass: this.confirmNewPassword })
        .then((result) => {
          if (result) {
            this.confirmChange = true;
          }
        });
    },
    showPassword(e) {
      const oldPassword = document.getElementById('oldpass');
      const newPassword = document.getElementById('newpass');
      const confirmPassword = document.getElementById('confirmpass');
      const passwordIcon = document.querySelectorAll('.change-password i');
      if (oldPassword.type === 'password') {
        oldPassword.type = 'text';
        newPassword.type = 'text';
        confirmPassword.type = 'text';
        for (var i = 0; i < passwordIcon.length; i++) {
          passwordIcon[i].className = 'fa fa-eye-slash';
        }
      } else {
        oldPassword.type = 'password';
        newPassword.type = 'password';
        confirmPassword.type = 'password';
        for (var i = 0; i < passwordIcon.length; i++) {
          passwordIcon[i].className = 'fa fa-eye';
        }
      }
    },
    previewImage(event) {
      this.$validator.validate('userphoto').then(
        (result) => {
          if (result) {
            showPhotoModal();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );

      const that = this;
      function showPhotoModal() {
        const input = event.target;
        if (input.files && input.files[0]) {
          const reader = new FileReader();
          reader.onload = (e) => {
            that.updatedUserData.photo = e.target.result;
            that.changePhoto = true;
          };
          reader.readAsDataURL(input.files[0]);
          input.value = '';
        }
      }
    },
    deletePhoto() {
      this.updatedUserData.photo = '/static/img/avatars/user.svg';
      this.$validator.reset();
      this.updateUserData();
    }
  }
};
</script>

<style lang="scss">
/* Users photo */
.userphoto {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

.userphoto-img {
  width: 150px;
  height: 150px;

  object-fit: cover;
  border-radius: 100%;

  transition: opacity 0.3s;
}

.userphoto-edit {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
  z-index: 10;
}

.userphoto-field {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0;

  width: 100%;
  height: 100%;
  opacity: 0;
}
.userphoto i {
  transition: opacity 0.3s;
}

.userphoto:hover .userphoto-img {
  opacity: 0.2;
}
.userphoto:hover .userphoto-edit {
  opacity: 0.9;
}

.userphoto-icon:hover {
  opacity: 0.5;
}

.userphoto-delete:hover {
  opacity: 0.5;
}

/*Change photo*/

.modal-photo {
  display: flex;
  justify-content: center;
}

.new-photo {
  width: 150px;
  height: 150px;

  object-fit: cover;
  border-radius: 100%;
}
/* User profile */
.userprofile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 1rem;
  margin-left: 2rem;
  height: 100%;

  word-break: break-all;
  word-wrap: break-word;
}
.userprofile .role,
.userprofile .email,
.userprofile .location {
  position: relative;
  padding-left: 1.4rem;
}
.userprofile i {
  position: absolute;
  left: 0;
  top: 4px;
}
.userprofile .role i {
  left: 1px;
}
.userprofile .role {
  margin-bottom: 0;
}
.userprofile-name {
  display: block;
  margin-bottom: 1.5rem;
}

.userprofile-name p {
  margin-bottom: 0;
}
.userprofile-name .btn {
  margin-bottom: 0;
}
.username {
  margin-bottom: 0.3rem;
  font-size: 1.5rem;
  min-height: 2.3rem;
}

.useremail-edit {
  transition: all 1s ease;
}

/*Change password*/
.change-password span {
  cursor: pointer;
}
</style>
